import React from 'react'
import BottomNavbar from './BottomNav'
import TopNav from './TopNav'

export default function Navbar() {
  return (
    <div className='fixed z-50 w-full'>
      <TopNav />
      <BottomNavbar />
    </div>
  )
}
