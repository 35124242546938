// hooks/fetchCategories.js
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading, setCategory } from '@/reducers/categoriesReducer'; // Adjust the path as necessary
import { useGetCategoryMutation } from '@/actions/categoriesAction'; // Adjust the path as necessary

const useFetchCategories = () => { // Renamed the hook
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [loading, setLoadingState] = useState(true);
    const [error, setError] = useState(null);

    const [getCategories] = useGetCategoryMutation(); // Renamed the mutation to avoid confusion

    useEffect(() => {
        const fetchData = async () => {
            setLoadingState(true);
            dispatch(setLoading(true));
            try {
                const response = await getCategories();
                if (response.data) {
                    setCategories(response.data);
                    dispatch(setCategory(response.data)); // Update the Redux store
                } else {
                    setError('No data found');
                }
            } catch (err) {
                console.error(err);
                setError('Failed to fetch categories');
            } finally {
                setLoadingState(false);
                dispatch(setLoading(false));
            }
        };

        fetchData();
    }, [dispatch, getCategories]);

    return { categories, loading, error };
};

export default useFetchCategories;
