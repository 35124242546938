//src/actions/authActions.js
import { apiSlice } from "../services/api";

export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createCategory: builder.mutation({
            query: (formDataToSend) => ({
                url: "/categories",
                method: "POST",
                body: formDataToSend,
            }),
        }),

        getCategory: builder.mutation({
            query: () => ({
                url: "/categories",
                method: "GET",
            }),
        }),


    }),
});

export const {
    useGetCategoryMutation,
    useCreateCategoryMutation,
} = categoryApiSlice;