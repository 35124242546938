import Link from 'next/link';
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

export default function TopNav() {
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <>
            <header class='flex border-b py-2 px-1 sm:px-10 bg-gray-50 font-sans  tracking-wide relative z-50'>
                <div class='flex flex-wrap items-center gap-4 w-full'>
                    <a href="/">
                        <img src="/assets/logo.png" alt="logo" className='md:w-24 w-16 object-cover' />
                    </a>

                    <div id="collapseMenu"
                        class={`${toggle ? "max-lg:block" : "max-lg:hidden"} lg:!fdlex lg:flex-auto lg:ml-12  max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50`}>
                        <button onClick={handleToggle} id="toggleClose" class='lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 fill-black" viewBox="0 0 320.591 320.591">
                                <path
                                    d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                                    data-original="#000000"></path>
                                <path
                                    d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                                    data-original="#000000"></path>
                            </svg>
                        </button>

                        <div
                            class="lg:!flex lg:flex-auto max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">

                            <ul class='lg:flex lg:gap-x-8 max-lg:space-y-2'>
                                <li class='mb-6 hidden max-lg:block'>
                                    <a href="/">
                                        <img src="/assets/logo.png" alt="logo" className='w-32  object-cover' />
                                    </a>
                                </li>
                                <li class='max-lg:border-b max-lg:py-3'>
                                    <Link href='/'
                                        class='hover:text-flamingo-500 text-flamingo-500 block font-bold text-[15px]'>Home</Link>
                                </li>
                                <li class='max-lg:border-b max-lg:py-3'>
                                    <Link href='/about'
                                        class='hover:text-flamingo-500 text-gray-600 block font-bold text-[15px]'>How it Works</Link>
                                </li>
                                <li class='max-lg:border-b max-lg:py-3'>
                                    <Link href='/jobs'
                                        class='hover:text-flamingo-500 text-gray-600 block font-bold text-[15px]'>Find a Job
                                    </Link>
                                </li>
                            </ul>

                            <ul class='lg:flex lg:items-center ml-auto max-lg:block lg:space-x-8'>
                                <li class='max-lg:border-b max-lg:py-3 max-lg:mt-2'>
                                    <Link href="/register" className="hover:text-orange-600">Sign Up</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="border-l border-[#333] h-6 max-lg:hidden"></div>

                    <div class='flex items-center ml-auto sm:space-x-6 space-x-2'>
                        <Link href='/login' class='hover:text-flamingo-500 text-gray-600 block font-bold text-[15px]'>Log
                            in
                        </Link>
                        <Link
                            href="/client/postproject"
                            className="flex items-center whitespace-nowrap sm:px-5 px-3 sm:py-2.5 py-2 rounded-lg text-white text-sm tracking-wider font-medium border border-current outline-none bg-orange-500 hover:bg-flamingo-50 active:bg-orange-500"
                        >
                            <AiOutlinePlus className="mr-2 text-xs" /> {/* Add icon here */}
                            Post A Job
                        </Link>

                        <button onClick={handleToggle} id="toggleOpen" class={toggle ? "" : "lg:hidden"}>
                            <svg class="w-7 h-7" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>

        </>
    );
}
