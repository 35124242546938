import Link from 'next/link';
import React from 'react';

const Footer = () => {
    return (

        <div className='w-full bg-secondary relative'>


            {/* ss */}

            <div className="max-w-[1440px] mx-auto">
                <footer className="px-5 py-12">
                    <div className='grid md:grid-cols-4  grid-cols-2 '>

                        <div>
                            <h3 className="font-semibold text-lg mb-3 text-white pb-2">Useful Links</h3>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/about" className="inline-block text-footerText hover:underline  hover:text-primary/65">About Us</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/blog-list" className="inline-block text-footerText hover:underline  hover:text-primary/65">Blog</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/login" className="inline-block text-footerText hover:underline  hover:text-primary/65">Login</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/register" className="inline-block text-footerText hover:underline  hover:text-primary/65">Register</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/forgot-password" className="inline-block text-footerText hover:underline  hover:text-primary/65">Forgot Password</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Help & Support</h2>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Browse Candidates</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Clients Dashboard</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Job Packages</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Jobs Featured</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Post A Job</Link>
                                </li>
                            </ul>
                        </div>



                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Other Links</h2>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/freelancer-dashboard" className="inline-block text-footerText hover:underline  hover:text-primary/65">Freelancers</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/freelancer-portfolio" className="inline-block text-footerText hover:underline  hover:text-primary/65">Freelancer Details</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/project" className="inline-block text-footerText hover:underline  hover:text-primary/65">Project</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/project-details" className="inline-block text-footerText hover:underline  hover:text-primary/65">Project Details</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/post-project" className="inline-block text-footerText hover:underline  hover:text-primary/65">Post Project</Link>
                                </li>
                            </ul>
                        </div>


                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Featured Categories</h2>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/project" className="block text-footerText hover:underline  hover:text-primary">Illustration</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/project" className="block text-footerText hover:underline  hover:text-primary">Link Building</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300  hover:text-primary'>
                                    <Link href="/project" className="block text-footerText hover:underline  hover:text-primary">Python Dev</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 hover:text-primary '>
                                    <Link href="/project" className="block text-footerText hover:underline hover:text-primary ">Angular Dev</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 hover:text-primary '>
                                    <Link href="/project" className="block text-footerText hover:underline hover:text-primary ">Node JS</Link>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="flex flex-col md:flex-row justify-start items-center mt-2 pt-4 border-t border-gray-500 text-white ">
                        <p className='text-base'>&copy; 2024 iwork. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>
        </div>

    );
}

export default Footer;
