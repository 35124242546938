import { useGetCategoryMutation } from '@/actions/categoriesAction';
import useFetchCategories from '@/hooks/fetchCategories';
import generateCategoriesMetadata from '@/hooks/generateCategoriesMetadata';
import { selectCategory, setCategory, setLoading } from '@/reducers/categoriesReducer';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function BottomNavbar() {
    const contentRef = useRef(null);
    const scrollAmount = 100; // Amount of pixels to scroll each time
    const [isMegaMenuVisible, setMegaMenuVisible] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);

    const bottomNavbarRef = useRef(null);
    const { categories, loading, error } = useFetchCategories();

    const metadata = generateCategoriesMetadata()






    useEffect(() => {
        const navbarElement = bottomNavbarRef.current;

        const handleMouseEnter = () => setMegaMenuVisible(true);
        const handleMouseLeave = () => {
            setMegaMenuVisible(false);
            setActiveCategory(null);
        };

        if (navbarElement) {
            navbarElement.addEventListener('mouseenter', handleMouseEnter);
            navbarElement.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (navbarElement) {
                navbarElement.removeEventListener('mouseenter', handleMouseEnter);
                navbarElement.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);



    const handleMouseEnter = (id) => {
        setActiveCategory(id);
        setMegaMenuVisible(true);
    };

    const handleMouseLeave = () => {
        // Optional: You can decide to keep the menu open or close it on mouse leave
        // setActiveCategory(null);
        setMegaMenuVisible(false);
    };

    const handleCategoryClick = (id) => {
        // Toggle mega menu visibility on click
        if (activeCategory === id) {
            setMegaMenuVisible(!isMegaMenuVisible);
        } else {
            setActiveCategory(id);
            setMegaMenuVisible(true);
        }
    };

    const scrollLeft = () => {
        if (contentRef.current) {
            contentRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (contentRef.current) {
            contentRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <>
            {categories.length > 0 && (
                <div className=" bg-orange-500 overflow-hidden">
                    <div className="flex relative items-center space-x-2 py-1">
                        {/* Previous Button */}
                        <button
                            onClick={scrollLeft}
                            className="bg-black/10 left-0 top-1/2 transform -translate-y-1/2 absolute text-white rounded-full p-2 hover:bg-black/50"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                                <path d="M15 19L8 12l7-7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>

                        {/* Category Items */}
                        <ul
                            ref={contentRef}
                            className="flex   px-8  pe-12  transition-transform ease-in-out duration-500 whitespace-nowrap overflow-hidden"
                        >
                            {categories.map((category) => (
                                <li
                                    key={category.id}
                                    onMouseEnter={() => handleMouseEnter(category.id)}
                                    onClick={() => handleCategoryClick(category.id)}
                                    className='flex relative leading-relaxed text-sm'

                                >
                                    <Link href={{ pathname: "/jobs", query: { category: category.name } }} prefetch={false}>
                                        <span className="text-white whitespace-nowrap hover:text-black transition-colors duration-200 inline-block px-2">
                                            {category.name}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        {/* Next Button */}
                        <button
                            onClick={scrollRight}
                            className="bg-black/10 right-0 top-1/2 transform -translate-y-1/2 absolute text-white rounded-full p-2 hover:bg-black/50"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                                <path d="M9 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>

                    {isMegaMenuVisible && activeCategory && categories.find(cat => cat.id === activeCategory)?.subcategories.length > 0 && (<div id="mega-menu-full-cta-dropdown" className="mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600">
                        <div
                            onMouseLeave={handleMouseLeave}
                            className="max-w-screen-xl px-4 py-5 mx-auto flex text-sm text-gray-500 dark:text-gray-400 md:grid-cols-3 md:px-6">
                            <div className="grid grid-cols-1">
                                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                                    {categories.find(cat => cat.id === activeCategory)?.name}
                                </div>
                                <ul className="mt-2 grid grid-flow-col grid-rows-4 gap-y-2 gap-x-24 items-center">
                                    {categories.find(cat => cat.id === activeCategory)?.subcategories.map(sub => (
                                        <li key={sub.id} className="text-start flex relative leading-relaxed text-xs">
                                            <Link
                                                href={{
                                                    pathname: "jobs",
                                                    query: { subcategory: sub.name },
                                                }}
                                                prefetch={false}
                                                scroll={true}
                                                className="hover:text-primary"
                                            >
                                                {sub.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            )}
        </>
    );
}
