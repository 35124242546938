// src/hooks/generateMetadata.js

import useFetchCategories from "./fetchCategories";



export default async function generateCategoriesMetadata() {
    const { categories, loading, error } = useFetchCategories();

    try {
    } catch (error) {
        console.error("Error fetching all category data:", error);
        return {
            title: "Categories Not Found",
            description: "No categories available at this time.",
            keywords: ['categories', 'not found', 'I Work Freelancer Marketplace'],
            openGraph: {
                title: "Categories Not Found - I Work Freelancer Marketplace",
                description: "No categories available at this time.",
                siteName: "I Work Freelancer Marketplace",
                images: [
                    {
                        url: 'https://test.iworkfreelancer.com/images/default-image.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Categories Not Found',
                    },
                ],
                locale: 'en_US',
                type: 'website',
            },
            twitter: {
                card: 'summary_large_image',
                title: "Categories Not Found - I Work Freelancer Marketplace",
                description: "No categories available at this time.",
                images: ['https://test.iworkfreelancer.com/images/default-image.jpg'],
            },
        };
    }

    // Handle case where categories could not be fetched
    if (!categories || categories.length === 0) {
        return {
            title: "Categories Not Found",
            description: "No categories available at this time.",
            keywords: ['categories', 'not found', 'I Work Freelancer Marketplace'],
            openGraph: {
                title: "Categories Not Found - I Work Freelancer Marketplace",
                description: "No categories available at this time.",
                siteName: "I Work Freelancer Marketplace",
                images: [
                    {
                        url: 'https://test.iworkfreelancer.com/images/default-image.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Categories Not Found',
                    },
                ],
                locale: 'en_US',
                type: 'website',
            },
            twitter: {
                card: 'summary_large_image',
                title: "Categories Not Found - I Work Freelancer Marketplace",
                description: "No categories available at this time.",
                images: ['https://test.iworkfreelancer.com/images/default-image.jpg'],
            },
        };
    }

    // Generate metadata for all categories
    const metadata = categories.map(category => ({
        title: `${category.name} - Explore this category`,
        description: category.description || "Explore this category on I Work Freelancer Marketplace.",
        keywords: [category.name, 'Jobs', 'Clients', 'I Work Freelancer Marketplace', 'student'],
        openGraph: {
            title: `${category.name} - I Work Freelancer Marketplace`,
            description: category.description || "Explore this category on I Work Freelancer Marketplace.",
            url: `https://test.iworkfreelancer.com/categories/${category.slug}`, // Assuming slug exists
            siteName: "I Work Freelancer Marketplace",
            images: [
                {
                    url: category.image || 'https://test.iworkfreelancer.com/images/default-image.jpg', // Fallback image
                    width: 800,
                    height: 600,
                    alt: category.name || 'Category Image',
                },
            ],
            locale: 'en_US',
            type: 'website',
        },
        twitter: {
            card: 'summary_large_image',
            title: `${category.name} - I Work Freelancer Marketplace`,
            description: category.description || "Explore this category on I Work Freelancer Marketplace.",
            images: [category.image || 'https://test.iworkfreelancer.com/images/default-image.jpg'], // Fallback image
        },
    }));

    return metadata; // Return array of metadata objects for all categories
}
